import { utils, WorkBook, writeFileXLSX } from 'xlsx'
import { ListEntry } from 'proto-js'

import {
  ALERT_TITLES,
  ALIAS_TITLES,
  LOCATION_TITLES,
  ADDITIONAL_TITLES
} from './titles'

import {
  TListEntryDetails,
  TAliasDetails,
  TLocationDetails,
  TAdditionalDetails,
  TReportData
} from './types'

import {
  generateSheet,
  generateListEntryDetails,
  generateAliasDetails,
  generateLocationsDetails,
  generateAdditionalDetails
} from './utils'

export function generateData(data: typeof ListEntry[]): TReportData {
  const alertRows: TListEntryDetails[] = []
  let aliasRows: TAliasDetails[] = []
  let locationRows: TLocationDetails[] = []
  let additionalRows: TAdditionalDetails[] = []

  data.forEach((listEntry, index) => {
    const listEntryDetails = generateListEntryDetails(listEntry, index)

    alertRows.push({ ...listEntryDetails })

    aliasRows = aliasRows.concat(
      generateAliasDetails(listEntry, listEntryDetails)
    )
    locationRows = locationRows.concat(
      generateLocationsDetails(listEntry, listEntryDetails)
    )
    additionalRows = additionalRows.concat(
      generateAdditionalDetails(listEntry, listEntryDetails)
    )
  })

  return {
    alertRows,
    aliasRows,
    locationRows,
    additionalRows
  }
}

function generateSheets(workbook: WorkBook, sheetData: TReportData) {
  generateSheet(workbook, 'Alerts', sheetData.alertRows, ALERT_TITLES)
  generateSheet(
    workbook,
    'Aliases',
    sheetData.aliasRows,
    ALERT_TITLES.concat(ALIAS_TITLES)
  )
  generateSheet(
    workbook,
    'Locations',
    sheetData.locationRows,
    ALERT_TITLES.concat(LOCATION_TITLES)
  )
  generateSheet(
    workbook,
    'Additional Information',
    sheetData.additionalRows,
    ALERT_TITLES.concat(ADDITIONAL_TITLES)
  )
}

export function generateReport(
  title: string,
  filename: string,
  entities: typeof ListEntry[]
): void {
  /* Create worksheet from HTML DOM TABLE */
  const wb = utils.book_new()
  wb.Props = { Title: title }

  const sheetData = generateData(entities)

  generateSheets(wb, sheetData)

  /* Export to file (start a download) */
  writeFileXLSX(wb, filename, {})
}
