import { utils, WorkBook, writeFileXLSX } from 'xlsx'
import { formatDateISO8601 } from '@/lib/utils'
import { TExportEntityItem } from '@/components/lib/Reporting'
import { getHitScore } from '@/lib/entity'

import {
  ALIAS_TITLES,
  LOCATION_TITLES,
  ADDITIONAL_TITLES,
  REFERENCE_ID_TITLE,
  SCREENING_INPUT_INDEX_TITLE,
  SCREEN_TITLES,
  ALERT_TITLES_WITH_SCORE
} from './titles'

import {
  TListEntryDetails,
  TAliasDetails,
  TLocationDetails,
  TAdditionalDetails,
  TScreenDetails,
  THitsWithScreen,
  TReportData
} from './types'

import {
  generateSheet,
  generateListEntryDetails,
  generateAliasDetails,
  generateLocationsDetails,
  generateAdditionalDetails
} from './utils'

export function generateData(data: THitsWithScreen[]): TReportData {
  const alertRows: TListEntryDetails[] = []
  let aliasRows: TAliasDetails[] = []
  let locationRows: TLocationDetails[] = []
  let additionalRows: TAdditionalDetails[] = []

  data.forEach((screen) => {
    screen.hits.forEach((hit, index) => {
      const hitProto = hit.protobuf
      const listEntry = hitProto.getListEntry()
      const score = getHitScore(hitProto)

      const listEntryDetails = generateListEntryDetails(
        listEntry,
        index,
        (screen.screenEntityIndex || 0) + 1,
        screen.referenceId ?? ''
      )

      listEntryDetails['Score'] = score

      alertRows.push({ ...listEntryDetails })

      aliasRows = aliasRows.concat(
        generateAliasDetails(listEntry, listEntryDetails)
      )
      locationRows = locationRows.concat(
        generateLocationsDetails(listEntry, listEntryDetails)
      )
      additionalRows = additionalRows.concat(
        generateAdditionalDetails(listEntry, listEntryDetails)
      )
    })
  })

  return {
    alertRows,
    aliasRows,
    locationRows,
    additionalRows
  }
}

function prependTitles(titles: string[]) {
  return [REFERENCE_ID_TITLE, SCREENING_INPUT_INDEX_TITLE, ...titles]
}

function generateSheets(
  workbook: WorkBook,
  screenData: TScreenDetails[],
  sheetData: TReportData
) {
  generateSheet(
    workbook,
    'Screening Input',
    screenData,
    prependTitles(SCREEN_TITLES)
  )

  const alert_titles = ALERT_TITLES_WITH_SCORE

  generateSheet(
    workbook,
    'Alerts',
    sheetData.alertRows,
    prependTitles(alert_titles)
  )
  generateSheet(
    workbook,
    'Aliases',
    sheetData.aliasRows,
    prependTitles(alert_titles.concat(ALIAS_TITLES))
  )
  generateSheet(
    workbook,
    'Locations',
    sheetData.locationRows,
    prependTitles(alert_titles.concat(LOCATION_TITLES))
  )
  generateSheet(
    workbook,
    'Additional Information',
    sheetData.additionalRows,
    prependTitles(alert_titles.concat(ADDITIONAL_TITLES))
  )
}

function getDobString(dob?: Date | string): string {
  if (dob instanceof Date) {
    // Emulate the format input to the batch report
    return formatDateISO8601(dob)
  }

  return dob || ''
}

function generateScreenData(entities: TExportEntityItem[]) {
  return entities.map((entity) => {
    const screenEntity = entity.screenEntity

    return {
      [REFERENCE_ID_TITLE]: screenEntity.referenceId,
      [SCREENING_INPUT_INDEX_TITLE]: entity.index + 1,
      Name: screenEntity.name,
      Location: screenEntity.location,
      'ID Number': screenEntity.idNumber,
      DOB: getDobString(screenEntity.dob)
    }
  })
}

function generateHits(entities: TExportEntityItem[]) {
  return entities.map((entity) => {
    return {
      screenEntityIndex: entity.index,
      referenceId: entity.screenEntity.referenceId,
      hits: entity.screenEntity.hits
    }
  })
}

export function generateReport(
  title: string,
  filename: string,
  entities: TExportEntityItem[]
): void {
  /* Create worksheet from HTML DOM TABLE */
  const wb = utils.book_new()
  wb.Props = { Title: title }

  const screenData = generateScreenData(entities)
  const hits = generateHits(entities)
  const sheetData = generateData(hits)

  generateSheets(wb, screenData, sheetData)

  /* Export to file (start a download) */
  writeFileXLSX(wb, filename, {})
}
